import React, { useEffect, useMemo, useState } from 'react'
import { useCommonNotification } from '@monetization/hpaip-notification-rules-react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import useGetText from '@/hooks/useGetText'
import ErrorCard from '../ErrorCard'
import HpAipSection from '../HpAipSection/HpAipSection'
import { APIError } from './styles'

const HpAllInOneComponent = (props) => {
  const getText = useGetText('SubscriptionManagement')
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)
  const { hpais } = props?.commonProps ?? null
  const subscription = hpais[0]
  const {
    enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment
  } = useFlags()
  const flagObject = {
    enablePaperOnPaaS: enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: enableAddPaperPostEnrollment
  }
  const commonNotification = useCommonNotification(
    'Subscriptions',
    subscription?.subscriptionId,
    flagObject,
    null,
    null
  )

  const printerData = commonNotification?.printerData

  useEffect(() => {
    if (printerData?.root != null) {
      setLoading(false) // Data has been loaded
      setError(false) // No error
    } else if (printerData?.error) {
      setLoading(false) // Stop loading if there's an error
      setError(true) // Set error state
    }
  }, [printerData?.error, printerData?.root])
  const renderSubscriptions = useMemo(() => {
    if (printerData?.root === null) return null

    return (
      <HpAipSection
        {...props}
        printerProps={printerData}
        commonProps={props?.commonProps}
        commonNotification={commonNotification}
      />
    )
  }, [commonNotification, printerData, props])

  return (
    <>
      {loading && <LoadingHandler loading={loading} error={false} />}
      {!loading && error && (
        <APIError>
          <LoadingHandler
            loading={loading}
            error={error}
            customError={
              <ErrorCard
                getText={getText}
                subtitle={getText('errorScreen.hpOneSubtitle')}
              />
            }
          ></LoadingHandler>
        </APIError>
      )}
      {renderSubscriptions}
    </>
  )
}
export default HpAllInOneComponent
