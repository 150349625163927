import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { gray6, gray12, white, hpBlue7 } = tokens.color
const { size1, size2, size3, family0, lineHeight3, lineHeight2 } =
  tokens.typography
const {
  cornerRadius4,
  size8: layoutSize8,
  size1: layoutSize1,
  size4: layoutSize4
} = tokens.layout

interface StyledHeaderActionProps {
  isDisabled?: boolean
}

export const StyledHeaderActionButton = styled.button<StyledHeaderActionProps>`
  font-family: ${family0};
  font-style: normal;
  font-weight: 400;
  font-size: ${size1};
  line-height: ${lineHeight2};
  background: none;
  border: none;
  padding: 0;
  color: ${(props) => (props.isDisabled ? '#A3A3A3' : '#0073A8')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
  &:hover {
    text-decoration: none;
  }
  &:focus {
    border: 1px solid ${hpBlue7};
  }
`

export const TextDividerStyle = styled.span`
  padding: 0 ${tokens.layout.size1};
  font-weight: 400;
  font-size: ${tokens.typography.size2} !important;
  line-height: ${tokens.typography.lineHeight2};
`
export const ReactiveSubscriptionButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: ${size2};
  width: 100%;
`
export const CardContainer = styled.div`
  padding: ${layoutSize8};
  border-radius: ${cornerRadius4};
  border: 1px solid ${gray6};
  background: ${white};
`
export const CardLabel = styled.p`
  color: ${gray12};
  font-family: ${family0};
  font-size: ${size3};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight3};
  margin-top: ${layoutSize1};
`
export const CardText = styled.p`
  color: ${hpBlue7};
  font-family: ${family0};
  font-size: ${size2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`
export const InsideCardTwo = styled.div`
  display: flex;
  gap: ${layoutSize4};
`
export const ModalContent1 = styled.div`
  display: flex;
  gap: ${layoutSize4};
  flex-wrap: wrap;
  word-wrap: break-word;
  width: fit-content;
  max-width: 100%;
  margin-bottom: 20px;
`
export const ModalContent2 = styled.div`
  display: flex;
  gap: ${layoutSize4};
  flex-wrap: wrap;
  word-wrap: break-word;
  width: fit-content;
  max-width: 100%;
`
