import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
const { size1, lineHeight1 } = tokens.typography
const { size2: layoutSize2, size3 } = tokens.layout

export const HeaderContentStyle = styled.div`
  font-size: ${size1};
  line-height: ${lineHeight1};
  diplay: flex;
  flex-flow: column;
  width: 100%;
  div {
    display: flex;
  }
  > span {
    display: block;
  }

  .planID {
    margin-bottom: ${size1};
  }

  .paymentInfo-top {
    flex-direction: column;
    > span {
      font-size: ${size1};
    }
  }

  .paymentInfo-bottom {
    font-size: ${size1};
    div {
      align-items: center;
    }
    justify-content: space-between;

    .pib-right {
      font-size: ${size3};
      color: ${tokens.color.gray9};
      gap: ${layoutSize2};
    }

    @media screen and (max-width: 370px) {
      flex-wrap: wrap;
      .pib-left {
        width: 100%;
        min-width: 170px;
      }
      .pib-right {
        justify-content: flex-end;
        width: 100%;
      }
    }
  }
`

export const ManagementLinkStyle = styled.div`
  padding-top: 5px;
`

export const StyledImage = styled.img<{ src?: string; alt?: string }>`
  width: 40px;
`

export const NextPaymentLabel = styled.p`
  font-size: ${tokens.typography.size1};
  line-height: ${tokens.typography.lineHeight2};
  color: ${tokens.color.gray9};
  margin-bottom: 4px;
`
