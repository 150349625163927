import React, { useEffect, useMemo, useState } from 'react'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import useGetText from '../../../../hooks/useGetText'
import { getSubscriptionKey } from '../../../../helpers/uiConvert'
import InstantInkSubscriptionComponent from './InstantInkSubscriptionComponent'
import { sortInstantInkSubscriptions } from '../../helpers/sortInstantInkSubscriptions'
import ErrorCard from '../ErrorCard'

const InstantInkComponent = (props) => {
  const getText = useGetText('SubscriptionManagement')
  const [isError, setIsError] = useState<boolean>(false)

  const { iInk, iiError, isFetching } = props?.commonProps ?? null

  const hasSubscriptions = iInk && iInk.length > 0

  useEffect(() => {
    if (iiError || !hasSubscriptions) {
      setIsError(true)
    }
  }, [iiError, hasSubscriptions])

  const sortedInstantInkSubscriptions = sortInstantInkSubscriptions(iInk || [])

  const renderSubscriptions = useMemo(() => {
    if (!hasSubscriptions) return null

    return iInk.map((iisubscription) => {
      const subscriptionKey = getSubscriptionKey(iisubscription?.subscription)
      const data = sortedInstantInkSubscriptions.find(
        (ii) =>
          ii.subscription?.accountIdentifier ===
          iisubscription?.subscription['accountIdentifier']
      )

      return (
        <InstantInkSubscriptionComponent
          {...props}
          data={data}
          t={getText}
          key={subscriptionKey}
          isError={isError}
        />
      )
    })
  }, [getText, isError])

  return (
    <>
      {isFetching && (
        <LoadingHandler
          loading={isFetching}
          error={isError}
          customError={
            <ErrorCard
              getText={getText}
              subtitle={getText('errorScreen.subtitle')}
            />
          }
        ></LoadingHandler>
      )}
      {renderSubscriptions}
    </>
  )
}

export default InstantInkComponent
