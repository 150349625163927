import * as React from 'react'
import { Container, TextBlock, Backface } from './CardError.styles'

interface CardErrorProps {
  texts: (string | JSX.Element)[]
  icon?: JSX.Element
  forceHeight?: boolean
  disabled?: boolean
}

export const CardError: React.FunctionComponent<CardErrorProps> = ({
  icon,
  texts,
  forceHeight = true,
  disabled = false
}) => {
  return (
    <>
      {forceHeight ? <Backface /> : null}
      <Container $disabled={disabled}>
        {icon ? <div data-testid="icon">{icon}</div> : null}
        {texts.map((text, index) => (
          <TextBlock as={typeof text === 'string' ? 'p' : 'div'} key={index}>
            {text}
          </TextBlock>
        ))}
      </Container>
    </>
  )
}
