import React, { useEffect } from 'react'
import Accordion from '@veneer/core/dist/scripts/accordion'
import { Container } from './style'
import {
  SubscriptionAccordionCollapsed,
  SubscriptionAccordionExpanded,
  publishEvent,
  ModuleDisplayed
} from '@/utils/analytics'

function HpAipAccordion({ header, body, ...extraProps }): JSX.Element {
  const { expanded, id, dataTestid, subscription, showFirstHourCancellation } =
    extraProps

  const [data, setData] = React.useState([
    {
      expanded: expanded ?? false,
      content: body,
      header,
      disabled: !body
    }
  ])

  const handleExpand = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0

    setData((state) => {
      const updatedData = [...state]
      updatedData[index].expanded = true
      return updatedData
    })

    publishEvent(SubscriptionAccordionExpanded, { subscription })
  }

  const handleCollapse = (_, index) => {
    if (index === undefined && typeof index === 'undefined') index = 0
    else {
      setData((state) => {
        const updatedData = [...state]
        updatedData[index].expanded = false
        return updatedData
      })
    }
    publishEvent(SubscriptionAccordionCollapsed, { subscription })
  }

  useEffect(() => {
    publishEvent(ModuleDisplayed, { subscription })
  }, [])

  useEffect(() => {
    setData((state) => [{ ...state[0], header }])
  }, [header])

  return (
    <Container showFirstHourCancellation={showFirstHourCancellation}>
      <Accordion
        background="filled"
        border="dropShadow"
        gutter={12}
        items={data}
        onCollapse={handleCollapse}
        onExpand={handleExpand}
        id={id}
        data-testid={dataTestid}
      />
    </Container>
  )
}

export default HpAipAccordion
