import Images from '@/assets/images'

import { HPOneSubscriptionHeader } from '@/components/HPOneSubscriptionHeader/HPOneSubscriptionHeader'
import SubscriptionAccordion from '@/components/SubscriptionAccordion'
import { SubscriptionWrapper } from '@/components/SubscriptionWrapper/SubscriptionWrapper'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import React, { useEffect, useMemo, useState } from 'react'
import {
  AccordionHeader,
  Container,
  DeviceIcon,
  Panel,
  PreArrivalPanel,
  NotificationSection
} from './styles'
import {
  disabledStates,
  mblEntityType,
  notOnboardedStates
} from '@/types/Subscription'
import IconLaptop from '@veneer/core/dist/esm/scripts/icons/icon_laptop'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import useGetText from '@/hooks/useGetText'
import ErrorCard from '../ErrorCard'
import { ProgressContainer } from '../SubscriptionManagement/styles'
import Skeleton from '../Skeleton'
import { getMainEntity } from '@/helpers/uiConvert'
import {
  useCommonNotification,
  SubscriptionOptionsResponse
} from '@monetization/hpaip-notification-rules-react'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
import useShippingAddress from '@/hooks/useShippingAddress'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SubscriptionAnalyticsProvider } from '@/context/SubscriptionAnalyticsProvider'
export default function HPOneSubscription(props) {
  const [optionsDataLoading, setOptionsDataLoading] = useState(true)
  const [printerOptionDetails, setPrinterOptionDetails] =
    useState<SubscriptionOptionsResponse>(null)
  const getText = useGetText('SubscriptionManagement')
  const { subscription, subscriptionKey, ...parentProps } = props
  const { paymentMethodDetailsIsLoading, subscriptionDetailsIsLoading } =
    useSubscriptionDetails()
  const mainEntity = getMainEntity(subscription)
  const subscriptionState = mainEntity?.state
  const {
    enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment
  } = useFlags()
  const flagObject = {
    enablePaperOnPaaS: enableManagePaperPostEnrollment,
    enableBuyerRemorseCancellation: enableBuyerRemorseCancellation,
    enableAddPaperPostEnrollment: enableAddPaperPostEnrollment
  }
  const commonNotification = useCommonNotification(
    'Subscriptions',
    subscription?.subscriptionId,
    flagObject,
    null,
    null
  )
  const [updatedNotificationsList, setUpdatedNotificationsList] = useState([])
  const [isAddedPaperNotification, setIsAddedPaperNotification] =
    useState(false)
  const printerData = commonNotification?.printerData
  const isRemovePaperEnabledFlag = printerData?.isRemovePaper
  const activePaperSubscription = printerData?.activePaperSubscription
  const isPaperEligible = printerData?.paperEligibilityInfo?.enabled
  const showFirstHourCancellation =
    (printerData?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerData?.printer?.fulfillment
  useEffect(() => {
    if (printerData?.optionsData) {
      if (printerData?.optionsData?.status) {
        setOptionsDataLoading(false)
      } else {
        setPrinterOptionDetails(printerData?.optionsData)
        setOptionsDataLoading(false)
      }
    }
  }, [printerData?.optionsData])
  const { shippingAddress } = useShippingAddress(mainEntity)

  const findPagesPerMonth = (
    optionsData: SubscriptionOptionsResponse,
    sku: string
  ) => {
    const optionDetails = optionsData?.entities[0]?.edit
    const inkPlan = optionDetails?.find(
      (plan) => plan?.product?.value?.productSku === sku
    )
    if (!inkPlan) return null
    return {
      sku: inkPlan?.product?.value?.productSku,
      pagesPerMonth: inkPlan?.product?.value?.pagesPerMonth ?? 0,
      rolloverPages: inkPlan?.product?.value?.maxRolloverPages ?? 0,
      additionalPages: inkPlan?.product?.value?.overageBlockSize ?? 0,
      additionalPagesPrice: inkPlan?.product?.value?.overageBlockPrice ?? 0
    }
  }
  const inkPlan = useMemo(() => {
    if (!printerOptionDetails) return null
    const values = printerData?.instantInk?.product?.value
    const { productSku } = values || {}
    const plan = findPagesPerMonth(printerOptionDetails, productSku)
    return plan
  }, [printerOptionDetails, printerData])

  const userNotOnBoarded =
    (mainEntity?.entityDetails?.pendingAssociationState !== 'completed' ||
      !mainEntity?.entityDetails?.uniqueDeviceId ||
      notOnboardedStates[mainEntity?.entityDetails?.pendingAssociationState]) ??
    false
  const isDisabled = disabledStates[subscriptionState] ?? false
  const deviceMap = {
    [mblEntityType.pc]: {
      icon: <IconLaptop size={60} />,
      text: getText('notification.preArrival.pc')
    },
    [mblEntityType.chromebook]: {
      icon: <IconLaptop size={60} />,
      text: getText('notification.preArrival.pc')
    },
    [mblEntityType.printer]: {
      icon: <IconPrinter size={60} />,
      text: getText('notification.preArrival.printer')
    }
  }

  useEffect(() => {
    if (!isAddedPaperNotification) return
    setUpdatedNotificationsList((prevList) => [
      ...prevList,
      {
        notificationType: 'positive',
        title: 'Your Paper Add-on Service was successfully added',
        description:
          '<p>Congratulations! You will receive an email when your paper is shipped. Your Paper Add-on Service billing will start when you receive your first shipment. It will be prorated to match your HP All-In Plan billing cycle.</p>',
        notificationID: 'AddPaperSuccess',
        showCloseButton: true
      }
    ])
  }, [isAddedPaperNotification])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rescindCanel = params.get('rescindPaperCanel')
    if (rescindCanel === 'success') {
      const notificationConfig = {
        notificationID: 'SubscriptionRescind',
        title: 'Your Paper Add-on Service has been successfully resumed',
        description:
          'The details are below. If you experience any issues, please visit <a href="https://support.hp.com/contact/select-product?originid=myaccount" target="_blank"> 24/7 Pro live support.</a>',
        notificationType: 'positive',
        showCloseButton: true
      }

      setUpdatedNotificationsList([notificationConfig])
      const newUrl = window.location.pathname
      if (params.has('rescindPaperCanel')) {
        params.delete('rescindPaperCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
  }, [commonNotification?.notificationsList])
  const multiNotificationArray = [
    ...(commonNotification?.notificationsList || []),
    ...updatedNotificationsList
  ]

  const analyticsProps = {
    analytics: props.analytics,
    subscription: subscription,
    printerData: printerData
  }
  const subscriptionCentralArea = (
    <div className="header-area">
      <img id="hpOneIcon" src={Images.imgHpOne} alt="logo-hpOne"></img>
      {subscription?.friendlySubscriptionId && (
        <span>
          {getText('planId')}: {subscription.friendlySubscriptionId}
        </span>
      )}
      <AccordionHeader>
        <HPOneSubscriptionHeader
          {...parentProps}
          printerData={printerData}
          printerOptionDetails={printerOptionDetails}
        />
      </AccordionHeader>
    </div>
  )

  if (
    paymentMethodDetailsIsLoading ||
    subscriptionDetailsIsLoading ||
    optionsDataLoading
  )
    return (
      <ProgressContainer>
        <Skeleton height="5vh" data-testid="skeleton" />
      </ProgressContainer>
    )

  return (
    <Container>
      {mainEntity ? (
        <SubscriptionAnalyticsProvider commonProps={analyticsProps}>
          <SubscriptionAccordion
            header={{
              centralArea: subscriptionCentralArea,
              previewArea: !showFirstHourCancellation && (
                <NotificationSection>
                  <div className="multipleNotification">
                    <MultipleNotification
                      multiNotificationArray={multiNotificationArray}
                      screenPath="/SubscriptionManagementReact/"
                    />
                  </div>
                </NotificationSection>
              ),
              'aria-label': 'subscription',
              id: 'accordion-header'
            }}
            body={
              !showFirstHourCancellation &&
              (userNotOnBoarded && subscriptionState !== 'inactive' ? (
                <PreArrivalPanel isDisabled={isDisabled}>
                  <DeviceIcon isDisabled={isDisabled}>
                    {deviceMap[mainEntity?.entityType]?.icon}
                  </DeviceIcon>
                  <div>{deviceMap[mainEntity?.entityType]?.text}</div>
                </PreArrivalPanel>
              ) : (
                <Panel>
                  <SubscriptionWrapper
                    isDisabled={isDisabled}
                    inkPlan={inkPlan}
                    subscriptionId={subscription?.subscriptionId}
                    controllerId={subscription?.controllerId}
                    isRemovePaperEnabledFlag={isRemovePaperEnabledFlag}
                    activePaperSubscription={activePaperSubscription}
                    shippingAddress={shippingAddress}
                    paperInfo={printerData?.paperInfo}
                    handleAddedPaperNotification={setIsAddedPaperNotification}
                    isPaperEnabled={isPaperEligible}
                  />
                </Panel>
              ))
            }
            id={`hpOneSubscriptionAccordion-${subscriptionKey}`}
            dataTestid="data-test-hpOneSubscription-accordion"
            subscription={subscription}
            expanded={true}
          />
        </SubscriptionAnalyticsProvider>
      ) : (
        <ErrorCard
          getText={getText}
          subtitle={getText('errorScreen.subtitle')}
        />
      )}
    </Container>
  )
}
