import React from 'react'
import { MfePropsType } from '@/types/mfeProps'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { withAppContext } from '@/context/AppProvider'
import SubscriptionManagement from '../SubscriptionManagement'
import SubscriptionManagementRoot from './SubscriptionManagementRoot'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
const SubscriptionManagementParent = (props: MfePropsType) => {
  const { pfEnableNewSubscriptionFlow } = useFlags()

  // Don't render anything until the flag resolves
  if (pfEnableNewSubscriptionFlow === undefined) {
    return <LoadingHandler loading={true} />
  }
  return (
    <>
      {pfEnableNewSubscriptionFlow ? (
        // New code
        <SubscriptionManagementRoot {...props} />
      ) : (
        //Existing code
        <SubscriptionManagement {...props} />
      )}
    </>
  )
}

export default withAppContext(SubscriptionManagementParent)
