import React, { useEffect, useState, useMemo } from 'react'
import HpAipAccordion from '../HpAipAccordion/HpAipAccordion'
import { getSubscriptionKey } from '@/helpers/uiConvert'
import useGetText from '@/hooks/useGetText'
import { SubscriptionAnalyticsProvider } from '@/context/SubscriptionAnalyticsProvider'
import {
  calculateContractDuration,
  findCoreOfferPlan,
  useLazyGetProducts
} from '../../api/services'
import {
  LoadingStyle,
  AccordionHeader,
  HpAipDesc,
  NotificationSection,
  Panel,
  MyPlanContainer
} from './style'
import Images from '@/assets/images'
import { MultipleNotification } from '@monetization/hpaip-ui-shared-components'
import { CoreOfferPlanInfo } from '@/types/planInfo'
import { LoadingHandler } from '@monetization/hpaip-ui-shared-components'
import { SubscriptionStateEnum } from '@monetization/hpaip-notification-rules-react'
import HpAipHeader from '../HpAipHeader/HpAipHeader'
import HpAipDevice from '../HpAipDevice/HpAipDevice'
import HpAipMyPlan from '../HpAipMyPlan/HpAipMyPlan'

export default function HpAipSection(props) {
  const [corePlan, setCorePlan] = useState<CoreOfferPlanInfo | null>()
  const [updatedNotificationsList, setUpdatedNotificationsList] = useState([])
  const [isAddedPaperNotification, setIsAddedPaperNotification] =
    useState(false)
  const getText = useGetText('SubscriptionManagement')

  const { printerProps, commonProps, commonNotification } = props
  const subscription = printerProps?.root
  const [getProducts, getProductsQuery] = useLazyGetProducts()
  const productSerialNumber = printerProps?.printer?.entityDetails?.serialNumber

  const duration = useMemo(() => {
    return calculateContractDuration(getProductsQuery?.data)
  }, [getProductsQuery?.data])

  const { disableCard, isPreArrival } = useMemo(() => {
    const disableCardState = [
      SubscriptionStateEnum.CANCELED,
      SubscriptionStateEnum.CANCELING
    ]
    if (!printerProps?.printer)
      return { disableCard: true, disableCardLink: true }

    return {
      disableCard: disableCardState.includes(printerProps?.printer?.state),
      disableCardLink: disableCardState.includes(printerProps?.printer?.state),

      isPreArrival:
        printerProps?.printer?.state === SubscriptionStateEnum.PENDING ||
        !printerProps?.printer?.entityDetails?.uniqueDeviceId
    }
  }, [printerProps?.printer])

  useEffect(() => {
    if (!printerProps?.printer) return

    const values = printerProps?.printer?.product?.value
    const { parentProductSku, productSku } = values || {}

    if (!parentProductSku || !productSku) return
    getProducts({
      variables: { sku: parentProductSku },
      onCompleted: (data) => setCorePlan(findCoreOfferPlan(data, productSku))
    })
  }, [getProducts, printerProps?.printer])

  const isLoading =
    !isPreArrival &&
    (!printerProps?.printer ||
      !corePlan ||
      getProductsQuery.loading ||
      (!getProductsQuery.called && !getProductsQuery.loading) ||
      !getProductsQuery.called)

  const emptyData = !isPreArrival && !isLoading

  const isError = Boolean(
    isPreArrival || (isLoading && emptyData) || getProductsQuery.error
  )

  const showFirstHourCancellation =
    (printerProps?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerProps?.printer?.fulfillment

  useEffect(() => {
    if (!isAddedPaperNotification) return
    setUpdatedNotificationsList((prevList) => [
      ...prevList,
      {
        notificationType: 'positive',
        title: 'Your Paper Add-on Service was successfully added',
        description:
          '<p>Congratulations! You will receive an email when your paper is shipped. Your Paper Add-on Service billing will start when you receive your first shipment. It will be prorated to match your HP All-In Plan billing cycle.</p>',
        notificationID: 'AddPaperSuccess',
        showCloseButton: true
      }
    ])
  }, [isAddedPaperNotification])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const rescindCanel = params.get('rescindPaperCanel')
    if (rescindCanel === 'success') {
      const notificationConfig = {
        notificationID: 'SubscriptionRescind',
        title: 'Your Paper Add-on Service has been successfully resumed',
        description:
          'The details are below. If you experience any issues, please visit <a href="https://support.hp.com/contact/select-product?originid=myaccount" target="_blank"> 24/7 Pro live support.</a>',
        notificationType: 'positive',
        showCloseButton: true
      }

      setUpdatedNotificationsList([notificationConfig])
      const newUrl = window.location.pathname
      if (params.has('rescindPaperCanel')) {
        params.delete('rescindPaperCanel')
      }
      window.history.replaceState({}, '', newUrl)
    }
  }, [commonNotification?.notificationsList])
  const multiNotificationArray = [
    ...(commonNotification?.notificationsList || []),
    ...updatedNotificationsList
  ]

  const headerCentralArea = (
    <div className="header-area">
      <img id="hpOneIcon" src={Images.imgHpOne} alt="logo-hpOne" />
      {subscription?.friendlySubscriptionId && (
        <span className="planid">
          {getText('planId')}: {subscription.friendlySubscriptionId}
        </span>
      )}

      <HpAipHeader
        printerProps={printerProps}
        commonProps={commonProps}
        corePlan={corePlan}
      />
    </div>
  )

  const AccordionBody = !showFirstHourCancellation && (
    <Panel>
      <HpAipDevice
        printerProps={printerProps}
        commonNotification={commonNotification}
        corePlan={corePlan}
        productSerialNumber={productSerialNumber}
        duration={duration}
        disableCard={disableCard}
        isLoading={isLoading}
        isError={isError}
        isPreArrival={isPreArrival}
      />
      <MyPlanContainer>
        <HpAipMyPlan
          printerProps={printerProps}
          handleAddedPaperNotification={setIsAddedPaperNotification}
        />
      </MyPlanContainer>
    </Panel>
  )

  const analyticsProps = {
    analytics: props.analytics,
    subscription: subscription,
    printerData: printerProps
  }

  const subscriptionKey = getSubscriptionKey(subscription)

  return (
    <LoadingStyle>
      <LoadingHandler loading={isLoading}>
        <SubscriptionAnalyticsProvider commonProps={analyticsProps}>
          <HpAipAccordion
            header={{
              centralArea: headerCentralArea,
              previewArea: !showFirstHourCancellation && (
                <AccordionHeader>
                  <HpAipDesc className="caption">
                    <NotificationSection>
                      <div className="multipleNotification">
                        <MultipleNotification
                          multiNotificationArray={multiNotificationArray}
                          screenPath="/SubscriptionManagementReact/"
                        />
                      </div>
                    </NotificationSection>
                  </HpAipDesc>
                </AccordionHeader>
              ),
              'aria-label': 'subscription',
              id: 'accordion-header'
            }}
            body={AccordionBody}
            subscription={subscription}
            id={`hpOneSubscriptionAccordion-${subscriptionKey}`}
            dataTestid="data-test-hpOneSubscription-accordion"
            expanded={true}
            showFirstHourCancellation={showFirstHourCancellation}
          />
        </SubscriptionAnalyticsProvider>
      </LoadingHandler>
    </LoadingStyle>
  )
}
