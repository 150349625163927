import React from 'react'
import HpAllInOneComponent from './components/HpAllInOneComponent/HpAllInOneComponent'
import InstantInkComponent from './components/InstantInkComponent/InstantInkComponent'
import useGetText from '@/hooks/useGetText'
import ErrorCard from '../ErrorCard'
const InitialComponent = (props) => {
  const getText = useGetText('SubscriptionManagement')
  const { commonProps } = props
  const hasSubscriptions = commonProps?.hpais && commonProps?.hpais.length > 0 //This is for 204 scenario
  return (
    <>
      {!commonProps?.hpais && !commonProps?.iInk ? (
        <ErrorCard
          getText={getText}
          subtitle={getText('errorScreen.subtitle')}
        />
      ) : (
        <>
          {commonProps?.hpais && hasSubscriptions && (
            <HpAllInOneComponent commonProps={commonProps} />
          )}

          {commonProps?.iInk && (
            <InstantInkComponent commonProps={commonProps} />
          )}
        </>
      )}
    </>
  )
}
export default InitialComponent
