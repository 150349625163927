import { tokens } from '@veneer/tokens'
import styled from 'styled-components'

const { highContrastGray, gray9 } = tokens.color
const { size2: fontSize2, lineHeight3 } = tokens.typography
const { size2, size4, size1, size5, smMin } = tokens.layout
export interface CardWithDisableProps {
  $disabled?: boolean
}

export const Container = styled.article<CardWithDisableProps>`
  position: absolute;
  top: -${size2};
  left: -${size2};
  background: #f8f8f8;
  width: calc(100% + ${size4});
  height: calc(100% + ${size4});
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ $disabled }) =>
    $disabled
      ? `
      pointer-events: none;
      color: ${highContrastGray} !important;

      * {
        color: ${highContrastGray} !important;
      }
    `
      : null}
`

export const TextBlock = styled.div`
  && {
    font-size: ${fontSize2};
    line-height: ${lineHeight3};
    text-align: center;
    padding: 0 ${size4};
    color: ${gray9};
  }

  &&:first-child {
    margin-top: ${size1};
  }

  && + * {
    margin-top: ${size5};
  }
`

export const Backface = styled.div`
  width: 100%;
  height: 200px;

  @media screen and (min-width: ${smMin}) {
    height: 300px;
  }
`
