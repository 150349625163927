import React, { useState } from 'react'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import { useSubscriptionDetails } from '@/context/SubscriptionDetailsContext'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'
import {
  mblEntityType,
  SubscriptionReturnStatusEnum,
  SubscriptionStateEnum,
  SubscriptionType
} from '@/types/Subscription'
import { getMainEntity } from '@/helpers/uiConvert'
import 'regenerator-runtime/runtime'
import Skeleton from '../Skeleton'
import { StyledHeaderActionButton, TextDividerStyle } from './styles'
import getLocation from '@/helpers/getLocation'
import BuyerRemorseModal from './BuyerRemorseModal'
import ConfirmCancellationModal from './ConfirmCancellationModal'
import useSubscription from '../../../src/hooks/useSubscription/useSubscription'
import { getCurrentOfferPayload } from '../../../src/helpers/getCurrentOfferPayload'

export function ManagementLinks(props) {
  const getText = useGetText('SubscriptionManagement')
  const printerInfo = props.printerData

  const payload = props.printerOptionDetails
    ? getCurrentOfferPayload(props.printerOptionDetails)
    : null
  const { authProvider, stack } = useShellContext()
  const { language, country } = getLocation()
  const showFirstHourCancellation =
    (printerInfo?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerInfo?.printer?.fulfillment
  const buttonDisable = printerInfo?.cancellationInfo?.buttonDisable ?? false
  const showCancelPlan = printerInfo?.cancellationInfo?.showCancelPlan ?? false
  const showCancellationStatus =
    printerInfo?.cancellationInfo?.showCancellationStatus ?? false
  const [showBuyerRemorseModal, setShowBuyerRemorseModal] = useState(false)
  const [error, setError] = useState(false)
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] =
    useState(false)
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const { properties: { portalLinkBehavior } = {} } = useShellContext()
  const {
    subscription,
    subscriptionDetailsIsLoading,
    returnDetails,
    returnDetailsLoading
  } = useSubscriptionDetails()
  const subscriptionService = useSubscription({ authProvider, stack })
  const mainEntity = getMainEntity(subscription as SubscriptionType)
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const managementHref =
    mainEntity?.entityType === mblEntityType.pc ||
    mainEntity?.entityType === mblEntityType.chromebook
      ? `/${country}/${language}${removeUCDE}/hp-all-in-plan`
      : `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/overview`

  const cancelPlanHref = `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/cancellation/${subscription.subscriptionId}`

  const showCancelLinkStates = [
    SubscriptionStateEnum.ACTIVE,
    SubscriptionStateEnum.PENDING,
    SubscriptionStateEnum.DEACTIVATING,
    SubscriptionStateEnum.SUSPENDED
  ]

  const handleManageSubscription = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      window.location.href = managementHref
    else
      window.open(process.env.HPSMART_URL + managementHref, getWindowTarget())
  }
  const handleCancelPlan = (): void => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      window.location.href = cancelPlanHref
    else
      window.open(process.env.HPSMART_URL + cancelPlanHref, getWindowTarget())
  }
  const getWindowTarget = (): string =>
    navigator.userAgent.includes('WebView') ? '_blank' : '_self'

  const checkReturnStatus = () => {
    const returnStatus =
      !returnDetailsLoading &&
      returnDetails?.parts?.find(
        (part) => part.modelSku === mainEntity?.product?.value?.productSku
      )?.status
    const hideCancelButtonStatuses = [
      SubscriptionReturnStatusEnum.TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL
    ]

    const hideCancelButton = hideCancelButtonStatuses.includes(
      returnStatus as SubscriptionReturnStatusEnum
    )

    if (
      mainEntity?.state === SubscriptionStateEnum.DEACTIVATING &&
      hideCancelButton
    ) {
      return false
    }
    return true
  }

  const handleConfirmCancellation = () => {
    subscriptionService
      .patch(printerInfo?.root?.subscriptionId, payload)
      .then(() => {
        setShowConfirmCancellationModal(true)
        setShowBuyerRemorseModal(false)
      })
      .catch(() => {
        setError(true)
        setShowBuyerRemorseModal(false)
      })
  }

  const handleCloseConfirmation = () => {
    setShowConfirmCancellationModal(false)
    window.location.reload()
  }

  const handleCloseBuyerRemorse = () => {
    setShowBuyerRemorseModal(false)
    setError(false)
  }

  return (
    <div>
      {subscriptionDetailsIsLoading ? (
        <Skeleton width="160px" height="30px" />
      ) : (
        <>
          <span>
            <StyledHeaderActionButton
              onClick={handleManageSubscription}
              data-testid="manage-subscription-link-btn"
              tabindex="0"
              id="all-in-plan-manage-subscription-link-btn"
            >
              {getText('managePlan')}
            </StyledHeaderActionButton>
          </span>
          {showCancelLinkStates.includes(
            mainEntity?.state as SubscriptionStateEnum
          ) && (
            <>
              {showFirstHourCancellation ? (
                <>
                  <TextDividerStyle>{`  |  `}</TextDividerStyle>
                  <span>
                    <StyledHeaderActionButton
                      onClick={() => setShowBuyerRemorseModal(true)}
                      data-testid="buyer-remorse-cancel-link-btn"
                      tabindex="0"
                      id="buyer-remorse-cancel-link-btn"
                    >
                      {getText('cancelPlan')}
                    </StyledHeaderActionButton>
                  </span>
                </>
              ) : (
                checkReturnStatus() && (
                  <>
                    {(showCancellationStatus || showCancelPlan) && (
                      <TextDividerStyle> {`  |  `}</TextDividerStyle>
                    )}
                    <span>
                      <StyledHeaderActionButton
                        onClick={handleCancelPlan}
                        data-testid="cancel-subscription-link-btn"
                        tabindex="0"
                        isDisabled={buttonDisable}
                        id="all-in-plan-cancel-subscription-link-btn"
                      >
                        {showCancellationStatus &&
                          getText('cancellationStatus')}
                        {showCancelPlan && getText('cancelPlan')}
                      </StyledHeaderActionButton>
                    </span>
                  </>
                )
              )}
            </>
          )}
        </>
      )}
      <BuyerRemorseModal
        showBuyerRemorseModal={showBuyerRemorseModal}
        handleCloseBuyerRemorse={handleCloseBuyerRemorse}
        handleConfirmCancellation={handleConfirmCancellation}
        error={error}
      />
      <ConfirmCancellationModal
        showConfirmCancellationModal={showConfirmCancellationModal}
        handleCloseConfirmCancellation={handleCloseConfirmation}
      />
    </div>
  )
}
