import IconCalendarDay from '@veneer/core/dist/esm/scripts/icons/icon_calendar_day'
import React from 'react'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import { DeviceInfoValue, ModelName, StyledDescriptionList } from './styles'

export interface DeviceDetailProps extends DefaultExportProps {
  modelName?: string
  productNumber?: string
  serialNumber?: string
  eligiblePrinterRefreshDate?: string
  editPrinterAnchor?: React.ReactNode
}

export const DeviceDetail = ({
  modelName = '',
  productNumber,
  serialNumber,
  eligiblePrinterRefreshDate,
  className,
  editPrinterAnchor,
  t
}: DeviceDetailProps) => {
  return (
    <div className={className}>
      {editPrinterAnchor}
      <ModelName description={modelName} />
      {productNumber && (
        <StyledDescriptionList
          description={t('device-detail.product-number', 'Product number')}
          value={productNumber}
          direction="column"
        />
      )}
      {serialNumber && (
        <StyledDescriptionList
          description={t('device-detail.serial-number', 'Serial number')}
          value={serialNumber}
          direction="column"
        />
      )}
      {eligiblePrinterRefreshDate && (
        <StyledDescriptionList
          description={t(
            'device-detail.printer-upgrade',
            'Eligible for printer upgrade'
          )}
          value={
            <DeviceInfoValue>
              <IconCalendarDay size={20} />
              <span>{eligiblePrinterRefreshDate}</span>
            </DeviceInfoValue>
          }
          direction="column"
        />
      )}
    </div>
  )
}
