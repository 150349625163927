import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { size0 } = tokens.typography
const { hpBlue7, gray3, gray9 } = tokens.color

export const Container = styled.div<{ $showFirstHourCancellation?: boolean }>`
  color: ${({ theme }) => theme.colorForegroundMedium};
  font-family: ${({ theme }) => theme.fontTextRegular};
  background-color: ${({ theme }) => theme.colorBackgroundContainer};
  width: 100%;

  #hpOneIcon {
    width: 105px;
    height: 1rem;
  }

  .planid {
    display: block;
    font-size: ${size0};
  }

  #hpOneSubscriptionAccordion {
    [aria-expanded] {
      svg {
        fill: ${hpBlue7};
      }
    }

    > div:nth-child(2) {
      padding: 0 0.01rem 0.5rem;
    }
    [aria-label^='Chevron Down'] {
        fill: #0278ab;
        margin-bottom: 21px;
    }
    [aria-hidden^='false'] {
      margin: 0px;
      padding: 1rem 1rem;
    }
  }

  .header-area {
    display: flex;
    flex-direction: column;
    span {
      font-size: ${size0};
      color: ${gray9};
    }
    gap: 8px;
  }

  #accordion-header {
    border: solid ${gray3};
    border-width:${(props) =>
      props.showFirstHourCancellation ? '0' : '0px 0px 1px'};
    border-radius: 0px !important;
    svg {
      margin-bottom: 80px;
      fill: ${hpBlue7};
      visibility:${(props) =>
        props.showFirstHourCancellation ? 'hidden' : 'visible'};
    }
    ::before {
      border-radius: 0px !important;
    position: relative !important;
  }
`
