import React from 'react'
import useGetText from '@/hooks/useGetText'
import Modal from '@veneer/core/dist/scripts/modal'
import Button from '@veneer/core/dist/scripts/button'
import { ReactiveSubscriptionButtonsContainer, ModalContent2 } from './styles'

interface ConfirmCancellationModalProps {
  showConfirmCancellationModal?: boolean
  handleCloseConfirmCancellation: () => void
}

const ConfirmCancellationModal: React.FC<ConfirmCancellationModalProps> = ({
  showConfirmCancellationModal,
  handleCloseConfirmCancellation
}) => {
  const getText = useGetText('buyer-remorse-cancellation')
  return (
    <Modal
      align="start"
      closeButton={true}
      maxWidth="575px"
      show={showConfirmCancellationModal}
      onClose={handleCloseConfirmCancellation}
      footer={
        <ReactiveSubscriptionButtonsContainer>
          <Button
            appearance="secondary"
            onClick={handleCloseConfirmCancellation}
          >
            {getText('Back-to-my-account', {
              defaultValue: 'Back to my subscription'
            })}
          </Button>
        </ReactiveSubscriptionButtonsContainer>
      }
      title={getText('title2', {
        defaultValue: 'Your cancellation is confirmed'
      })}
    >
      <ModalContent2>
        <p>
          {getText('subtitle2', {
            defaultValue:
              'We are sorry to see you go. You will receive a confirmation email.'
          })}
        </p>
      </ModalContent2>
    </Modal>
  )
}

export default ConfirmCancellationModal
