import React, { useState } from 'react'
import useGetText from '@/hooks/useGetText'
import useShellContext from '@/hooks/useShellContext'
import {
  mblEntityType,
  SubscriptionReturnStatusEnum,
  SubscriptionStateEnum
} from '@/types/Subscription'
import { StyledHeaderActionButton, TextDividerStyle } from './style'
import getLocation from '@/helpers/getLocation'
import {
  CancelSubscriptionButtonClicked,
  ManageSubscriptionButtonClicked,
  publishEvent
} from '@/utils/analytics'
import { useRetrieveReturns } from '../../../../hooks/useRetrieveReturns/useRetrieveReturns'
import useSubscription from '../../../../hooks/useSubscription'
import { getCurrentOfferPayload } from '../../../../helpers/getCurrentOfferPayload'
import HpAipBuyerRemorseModal from './HpAipBuyerRemorseModal'
import HpAipConfirmCancellationModal from './HpAipConfirmCancellationModal'

export default function HpAipLinks(props) {
  const getText = useGetText('SubscriptionManagement')
  const [showBuyerRemorseModal, setShowBuyerRemorseModal] = useState(false)
  const [error, setError] = useState(false)
  const [showConfirmCancellationModal, setShowConfirmCancellationModal] =
    useState(false)
  const subscription = props.printerData?.root
  const printerData = props.printerData
  const { language, country } = getLocation()
  const {
    properties: { portalLinkBehavior } = {},
    authProvider,
    stack
  } = useShellContext()
  const subscriptionService = useSubscription({ authProvider, stack })
  const hasUcdeInPath = window?.location?.pathname?.includes('/ucde') || false
  const removeUCDE = hasUcdeInPath ? '/ucde' : ''
  const { data: returnDetails, isLoading: returnDetailsLoading } =
    useRetrieveReturns(printerData?.root?.tenantId)
  const payload = printerData?.optionsData
    ? getCurrentOfferPayload(printerData?.optionsData)
    : null
  const showCancelPlan = printerData?.cancellationInfo?.showCancelPlan ?? false
  const showCancellationStatus =
    printerData?.cancellationInfo?.showCancellationStatus ?? false
  const buttonDisable = printerData?.cancellationInfo?.buttonDisable ?? false
  const managementHref =
    printerData?.printer?.entityType === mblEntityType.pc ||
    printerData?.printer?.entityType === mblEntityType.chromebook
      ? `/${country}/${language}${removeUCDE}/hp-all-in-plan`
      : `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/overview`

  const cancelPlanHref = `/${country}/${language}${removeUCDE}/hp-all-in-print-plan/cancellation/${printerData?.subscriptionId}`

  const showCancelLinkStates = [
    SubscriptionStateEnum.ACTIVE,
    SubscriptionStateEnum.PENDING,
    SubscriptionStateEnum.DEACTIVATING,
    SubscriptionStateEnum.SUSPENDED
  ]

  const showFirstHourCancellation =
    (printerData?.cancellationInfo?.firstHourCancellation ?? false) &&
    !printerData?.printer?.fulfillment

  const inActiveState =
    printerData?.root?.state === SubscriptionStateEnum.INACTIVE

  const checkReturnStatus = () => {
    const returnStatus =
      !returnDetailsLoading &&
      returnDetails?.parts?.find(
        (part) =>
          part.modelSku === printerData?.printer?.product?.value?.productSku
      )?.status
    const hideCancelButtonStatuses = [
      SubscriptionReturnStatusEnum.TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_TIMEOUT,
      SubscriptionReturnStatusEnum.COMPLETE_UNSUCCESSFUL
    ]

    const hideCancelButton = hideCancelButtonStatuses.includes(
      returnStatus as SubscriptionReturnStatusEnum
    )

    if (
      printerData?.printer?.state === SubscriptionStateEnum.DEACTIVATING &&
      hideCancelButton
    ) {
      return false
    }
    return true
  }

  const handleManagePlan = () => {
    publishEvent(ManageSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      window.location.href = managementHref
    else
      window.open(process.env.HPSMART_URL + managementHref, getWindowTarget())
  }
  const handleCancelPlan = (): void => {
    publishEvent(CancelSubscriptionButtonClicked, { subscription })
    if ((portalLinkBehavior as unknown as string) === 'relative')
      window.location.href = cancelPlanHref
    else
      window.open(process.env.HPSMART_URL + cancelPlanHref, getWindowTarget())
  }
  const getWindowTarget = (): string =>
    navigator.userAgent.includes('WebView') ? '_blank' : '_self'

  const handleConfirmCancellation = () => {
    subscriptionService
      .patch(printerData?.root?.subscriptionId, payload)
      .then(() => {
        setShowConfirmCancellationModal(true)
        setShowBuyerRemorseModal(false)
      })
      .catch(() => {
        setError(true)
        setShowBuyerRemorseModal(false)
      })
  }

  const handleCloseConfirmation = () => {
    setShowConfirmCancellationModal(false)
    window.location.reload()
  }

  const handleCloseBuyerRemorse = () => {
    setShowBuyerRemorseModal(false)
    setError(false)
  }

  return (
    <>
      <span>
        <StyledHeaderActionButton
          onClick={handleManagePlan}
          data-testid="manage-subscription-link-btn"
          tabindex="0"
          isDisabled={showFirstHourCancellation || inActiveState}
          id="all-in-plan-manage-subscription-link-btn"
        >
          {getText('managePlan')}
        </StyledHeaderActionButton>
      </span>
      {showCancelLinkStates.includes(
        printerData?.printer?.state as SubscriptionStateEnum
      ) && (
        <>
          {showFirstHourCancellation ? (
            <>
              <TextDividerStyle>{`  |  `}</TextDividerStyle>
              <span>
                <StyledHeaderActionButton
                  onClick={() => setShowBuyerRemorseModal(true)}
                  data-testid="buyer-remorse-cancel-link-btn"
                  tabindex="0"
                  id="buyer-remorse-cancel-link-btn"
                >
                  {getText('cancelPlan')}
                </StyledHeaderActionButton>
              </span>
            </>
          ) : (
            checkReturnStatus() && (
              <>
                {(showCancellationStatus || showCancelPlan) && (
                  <TextDividerStyle> {`  |  `}</TextDividerStyle>
                )}
                <span>
                  <StyledHeaderActionButton
                    onClick={handleCancelPlan}
                    data-testid="cancel-subscription-link-btn"
                    tabindex="0"
                    isDisabled={buttonDisable}
                    id="all-in-plan-cancel-subscription-link-btn"
                  >
                    {showCancellationStatus && getText('cancellationStatus')}
                    {showCancelPlan && getText('cancelPlan')}
                  </StyledHeaderActionButton>
                </span>
              </>
            )
          )}
        </>
      )}
      <HpAipBuyerRemorseModal
        showBuyerRemorseModal={showBuyerRemorseModal}
        handleCloseBuyerRemorse={handleCloseBuyerRemorse}
        handleConfirmCancellation={handleConfirmCancellation}
        error={error}
      />
      <HpAipConfirmCancellationModal
        showConfirmCancellationModal={showConfirmCancellationModal}
        handleCloseConfirmCancellation={handleCloseConfirmation}
      />
    </>
  )
}
