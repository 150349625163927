const defaultText = {
  'main-title': 'Paper Add-On Service',
  'sub-title': 'Frequently Asked Questions',
  'btn-label': 'Close',
  'accordion-data': {
    'id-1': {
      header: 'What is the paper add-on in the HP All-In Plan?',
      content:
        'HP is offering a new smart paper add-on to the HP All-In Plan, delivering responsibly-sourced 20lb, 96 Bright HP Paper to your so you never run out. Plans are based on pages printed each month, just like your All-In Plan – simply add the paper option when you sign up for your HP All-In Plan. We will replenish paper, one or three reams of paper at a time, based on your plan and printing habits. Your monthly payment includes paper replenishment as needed, monitoring of your paper printing, and shipping and delivery. The paper add-on option starts at $0.79/month in addition to your All-In Plan. You can roll over unused pages. Your plan is flexible and can be changed at any time, though it needs to match your All-In Plan at all times.'
    },
    'id-2': {
      header: 'How does the paper add-on work for the HP All-In Plan?',
      content:
        'The paper add-on service is based on the number of pages you print just like your HP All-In Plan. Simply click ‘Yes’ on the paper-add on service when you sign up for the HP All-In Plan. Once you sign up to the Paper Add-on Service, you don’t have to worry about running out of paper if you use your paper for printing purposes. Your printer will track your pages printed, so we can automatically send you new ream(s) when you run low and receive printer paper before you need it. HP only tracks pages printed through the All-In Plan printer subscribed to this service. The paper we send you should only be used for printing purposes in this subscribed printer. If you use the paper we send you for any other purpose other than printing in the HP All-In Plan printer, you run the risk of running out of paper without HP knowing. You are responsible for purchasing additional paper to use for any purpose other than printing in your subscribed printer.'
    },
    'id-3': {
      header: 'Can I cancel or change my paper add-on?',
      content:
        "Don't worry if your printing needs to change, you can easily change or cancel your paper plan anytime² without additional fees; however your paper plan must always match your ink plan level (100 pages of paper for your 100-page ink plan4). Cancelling your paper plan will not affect your ink plan; however, if you cancel your ink plan, it will automatically cancel your paper plan."
    },
    'id-4': {
      header: 'What kind of paper is included in the All-In Plan?',
      content:
        'The paper add-on service uses excellent quality, responsibly sourced 8.5x11, 20lb 96 Bright HP Paper that’s engineered for versatility to support a wide range of everyday color printing. This paper is designed for optimal performance when using Original HP printers and Original HP inks or toners. In addition, it is certified⁴ by the Forest Stewardship Council®, which means that we only source the paper from responsibly managed forests to contribute to healthy forests for future generations.'
    },
    'id-5': {
      header:
        'Do you offer other types of paper in the All-In Plan paper service?',
      content:
        'We only offer our premium quality, responsibly-sourced 8.5x11, 20lb 96 Bright HP Paper that’s optimized for HP printers and original HP inks and engineered for versatility to support a wide range of everyday printing. In the future, we may add other types of paper products.'
    },
    'id-6': {
      header: 'Is the paper in the add-on service sustainable?',
      content:
        'The HP Paper we use in the paper add-on service is certified4 by the Forest Stewardship Council® (FSC®) which means that we only source the paper from responsibly managed forests to guarantee healthy growth for future generations.'
    },
    'id-7': {
      header: 'Where is the paper add-on service available?',
      content:
        'HP All-In Plan paper add-on service is currently available only in the continental United States - excluding Alaska, Hawaii, US Territories, military bases, and PO Box addresses.'
    },
    'id-8': {
      header: 'What do I need to do to sign up?',
      content:
        'To sign up for the paper add-on service, you will need:<br>1. An HP All-In Plan printer. To see the HP All-In Plan printers go to HP All-In Plan [Link]for more details.2. An ongoing Internet connection for your printer.<br>3. A valid email address.4. A valid payment method (for billing purposes): Credit card, direct debit, or PayPal.5. An active HP All-In Plan.'
    }
  }
}

export const getDefaultText = (key: string) => {
  return defaultText[key]
}
