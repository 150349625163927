import imgLaptop from './img-laptop.svg'
import imgPrinter from './img-printer.svg'
import imgKeyboard from './img-keyboard.svg'
import imgMouse from './img-mouse.svg'
import imgCMY from './img-cmy.svg'
import pcPlaceholder from './pc-placeholder.png'
import printerPlaceholder from './printer-placeholder.png'
import PrinterImg from './Printer-img.png'
import PrinterX from './printer-x.svg'
import PrinterUpgrade from './printer-upgrade.svg'
import PaperBannerTablet from './Intersect-tab.png'
import PaperBannerMobile from './Intersect-mob.png'
import PaperReamOpen from './paper-ream-open.svg'
import AllInPlan from './All-In-Plan.svg'
import AOPModalBanner from './PaperAddOn_Full_2_2b.png'
import PaperBanner from './paper-banner.png'
const Images = {
  imgLaptop,
  imgPrinter,
  imgKeyboard,
  imgMouse,
  imgCMY,
  pcPlaceholder,
  printerPlaceholder,
  PrinterImg,
  PrinterX,
  PrinterUpgrade,
  PaperReamOpen,
  PaperBannerTablet,
  PaperBannerMobile,
  AllInPlan,
  AOPModalBanner,
  PaperBanner
}

export default Images
