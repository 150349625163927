import React from 'react'
import useGetText from '@/hooks/useGetText'
import '@veneer/core/dist/css/veneer.css'
import { useTheme } from '@veneer/theme'
import { ThemeProvider as StyleThemeProvider } from 'styled-components'
import { Container, EmptyState, Heading } from './styles'
import useInstantInkSubscriptionInfo from '../../hooks/useInstantInkSubscriptionInfo'
import useSubscriptions from '../SubscriptionManagementRoot/hooks/useSubscription'
import { checkIsSubscriptionEmpty } from './utils/checkIsSubscriptionEmpty'
import { MfePropsType } from 'src/types/mfeProps'
import InitialComponent from './InitialComponent'
const SubscriptionManagementRoot = ({ authProvider, stack }: MfePropsType) => {
  const getText = useGetText('SubscriptionManagement')
  const theme = useTheme()

  const {
    data: subscriptionData,
    isFetching: hpOneIsFetching,
    error: subscriptionError
  } = useSubscriptions(authProvider, stack)
  const { info } = useInstantInkSubscriptionInfo()

  const isLoading = hpOneIsFetching || info.isFetching

  const shouldDisplayEmptyState = checkIsSubscriptionEmpty(
    info,
    hpOneIsFetching,
    subscriptionData,
    subscriptionError
  )

  const commonProps = {
    hpais: subscriptionData,
    iInk: info?.data,
    subError: subscriptionError,
    iiError: info?.error,
    authProvider: authProvider,
    stack: stack,
    isFetching: info?.isFetching
  }

  const renderContent = () => {
    if (shouldDisplayEmptyState)
      return <EmptyState>{getText('emptyState')}</EmptyState>
    return <>{!isLoading && <InitialComponent commonProps={commonProps} />}</>
  }

  return (
    <StyleThemeProvider theme={theme}>
      <Container>
        <Heading role="heading" className="title-small">
          {getText('heading')}
        </Heading>
        {renderContent()}
      </Container>
    </StyleThemeProvider>
  )
}

export default SubscriptionManagementRoot
