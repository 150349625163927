import React from 'react'
import {
  MainTitle,
  ModalBody,
  StyledModal,
  SubTitle,
  TCModelHeader,
  Title
} from './styles'
import Images from '../../../assets/images'
import useGetText from '../../hooks/useGetText'
import { getDefaultText } from './defaultText'
import Markdown from 'markdown-to-jsx'
interface TermsAndConditionsProps {
  openModel?: boolean
  onClose?: () => void
}
export function TermsAndConditions({
  openModel,
  onClose
}: TermsAndConditionsProps) {
  const getText = useGetText('termsandConditions')
  return (
    <StyledModal
      show={openModel}
      closeButton
      onClose={onClose}
      data-testid="tos-modal"
      closeOnBlur={false}
    >
      <TCModelHeader>
        <img src={Images.AllInPlan} className="icons" alt="Hp All In Plan" />
        <MainTitle>
          {getText('Main-title', { defaultText: getDefaultText('Main-title') })}
        </MainTitle>
        <SubTitle>
          {getText('Sub-title', { defaultText: getDefaultText('Sub-title') })}
        </SubTitle>
      </TCModelHeader>
      <ModalBody>
        <Title>
          <Markdown
            options={{
              overrides: {
                p: {
                  component: (props) => <p>{props.children}</p>
                }
              }
            }}
          >
            {getText('termsandConditionsText', {
              defaultText: getDefaultText('termsandConditionsText')
            })}
          </Markdown>
        </Title>
      </ModalBody>
    </StyledModal>
  )
}
