import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import { DescriptionList } from '../../ui'

const { smMax, size8, size3, size1 } = tokens.layout

export const StyledDeviceImage = styled.img`
  width: 312px;
  height: 100%;

  @media screen and (max-width: ${smMax}) {
    margin: auto;
    width: 100%;
  }
`

export const ModelName = styled(DescriptionList)`
  margin: 0 0 ${size8};
  @media (min-width: 1007px) {
    margin: 0 0 40px;
  }
`

export const StyledDescriptionList = styled(DescriptionList)`
  margin-bottom: ${size3};
  gap: ${size1};
`

export const DeviceInfoValue = styled.div`
  display: flex;
  align-items: center;
  gap: ${size1};
`
