import React, { useMemo } from 'react'
import Images from '@/assets/images'
import { InstantInkManagementLinks } from '@/components/InstantInkManagementLinks'
import SubscriptionAccordion from '@/components/SubscriptionAccordion'
import useSettings from '@/hooks/useSettings'
import {
  AccordionHeaderII,
  ContainerII,
  PanelII,
  SubscriptionDescII,
  PaymentDetailsII,
  GPayPaymentII
} from './styles'
import ErrorCard from '../../../ErrorCard'
import useInstantInkPaymentInfo from '@/hooks/usePaymentMethodsInfo/usePaymentMethodsInfo'
import { logos } from '@/components/shared/PaymentInfo/helper'
import { SubscriptionIncludesPanelNoDeviceCache } from '@/components/shared/SubscriptionIncludesPanelNoDeviceCache'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { PaymentInfoStyle } from '../../../../../src/components/shared/PaymentInfo/styles'

export default function InstantInkSubscriptionComponent(props) {
  const {
    data: { subscription },
    t,
    isError
  } = props

  const { assetsProvider } = useSettings()
  const language = assetsProvider.getLanguage()
  const country = assetsProvider.getCountry()
  const { data: info } = useInstantInkPaymentInfo()
  const { enableIiGoogleWallet } = useFlags()

  const isGPayAvailable = useMemo(
    () => info?.creditCard?.cardSource === 'GPay' && enableIiGoogleWallet,
    [info, enableIiGoogleWallet]
  )

  const isDisabled = useMemo(
    () => subscription.state === 'obsolete',
    [subscription.state]
  )

  const paymentDetailsII = useMemo(
    () => (
      <PaymentDetailsII>
        {isGPayAvailable && (
          <GPayPaymentII>
            <div>via</div>
            <img src={Images.gPay} alt="gPay" />
          </GPayPaymentII>
        )}
        <img
          className="credit-card-type"
          src={logos[info?.creditCard?.cardTypeKey]}
        />
        {info?.creditCard?.cardNumber && (
          <div className="last-4-digits">
            XXXX-{info.creditCard.cardNumber.slice(-4)}
          </div>
        )}
      </PaymentDetailsII>
    ),
    [isGPayAvailable, info]
  )

  return (
    <ContainerII>
      {isError ? (
        <ErrorCard getText={t} subtitle={t('errorScreen.subtitle')} />
      ) : (
        <SubscriptionAccordion
          header={{
            centralArea: (
              <>
                <img
                  id="instantInkIcon"
                  src={Images.imgInstantInk2}
                  alt="instantInkIcon"
                />
                <span className="insinksubid">
                  {t('subscriptionId')}: {subscription.accountIdentifier}
                </span>
              </>
            ),
            previewArea: (
              <AccordionHeaderII>
                <SubscriptionDescII className="caption">
                  <PaymentInfoStyle>
                    <div className="paymentInfo-top">
                      {subscription?.printerFriendlyName ||
                        subscription?.printerModelName}
                      {subscription.nextChargeDate && (
                        <span>
                          {t('nextPaymentDue')}: {subscription.nextChargeDate}
                        </span>
                      )}
                    </div>
                  </PaymentInfoStyle>
                  <div className="links-card">
                    <InstantInkManagementLinks
                      {...props}
                      subscription={subscription}
                      country={country}
                      language={language}
                    />
                    {paymentDetailsII}
                  </div>
                </SubscriptionDescII>
              </AccordionHeaderII>
            ),
            'aria-label': 'instant-ink-subscription'
          }}
          body={
            <PanelII>
              <SubscriptionIncludesPanelNoDeviceCache
                t={t}
                subscription={subscription}
                isDisabled={isDisabled}
              />
            </PanelII>
          }
          expanded={true}
          id="instantInkSubscriptionAccordion"
          dataTestid="data-test-instantInkSubscription-accordion"
          subscription={subscription}
        />
      )}
    </ContainerII>
  )
}
