import * as React from 'react'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import {
  Aside,
  Container,
  Footer,
  Header,
  IconBlock,
  StyledCard
} from './styles'
import { CardTitle } from '../CardTitle'

export interface ICardProps extends Partial<DefaultExportProps> {
  title?: JSX.Element
  icon?: JSX.Element
  aside?: JSX.Element
  footer?: JSX.Element
  autoHeight?: 'true' | 'false'
  full?: 'true' | 'false'
  contentPosition?: 'bottom' | 'top'
}

export const Card: React.FunctionComponent<ICardProps> = ({
  children,
  title,
  icon,
  footer,
  className,
  aside,
  full,
  autoHeight,
  contentPosition = 'top'
}) => {
  return (
    <StyledCard
      className={className}
      $autoHeight={autoHeight}
      $full={full}
      content={
        <>
          <Container $contentPosition={contentPosition}>
            <section>
              {icon || title ? (
                <Header>
                  {icon && <IconBlock>{icon}</IconBlock>}
                  {title && <CardTitle as="h2">{title}</CardTitle>}
                </Header>
              ) : null}
              <article>{children}</article>
            </section>
            {footer && <Footer>{footer}</Footer>}
          </Container>
          {aside ? <Aside>{aside}</Aside> : null}
        </>
      }
    />
  )
}
